import React from 'react';
import {FAQNuevo} from 'src/templates';
import {Box, Container, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/styles';
import { Link } from "gatsby"
import iconoPreguntasInversionistas from "src/assets/svg/ayuda/icono-preguntas-inversionistas.svg"
// Imports para los contenidos
import { getContent } from 'src/queries';


export default function Inversiones() {
 const theme = useTheme();
 const sm = useMediaQuery(theme.breakpoints.down('md'));

 const faqInversiones = process.env.GATSBY_REACT_APP_REGION === "es-MX" ? null : getContent("faq-inversiones", process.env.GATSBY_REACT_APP_REGION)


 const Cabecera = () => {
  return <Box sx={{
   display: "flex",
   flexDirection: "row",
   alignItems: "flex-end",
   justifyContent: "center",
   color: 'text.secondary',
   minHeight: sm ? '92px' : '99px',
   height: '100%',
   padding: "36px 23px"
  }}/>
 };

 return (
  <Box sx={{ background: `${theme.palette.primary.main}20` }}>
   <Cabecera/>
   <Container>
    <Box sx={{ maxWidth: "1100px", margin: "0 auto"}}>
     <Box sx={{ padding: "72px 0"}}>
      <Box sx={{ marginBottom: "32px"}}>
       <Typography variant="h3" color="primary.dark" fontWeight={500}>Preguntas frecuentes</Typography>
      </Box>
      <Box sx={{ mt: 8, mb: 6}}>
       <Link to="/ayuda/" style={{ textDecoration: "none" }}>
        <Typography display="inline" color="secondary.main" sx={{fontWeight: 700}}>Preguntas frecuentes</Typography>
       </Link>
       <Typography display="inline" sx={{ ml: 2, mr: 2}}>{`>`}</Typography>
       <Typography display="inline">Inversiones</Typography>
      </Box>

      <FAQNuevo 
       titulo="Inversiones"
       descripcion="Preguntas frecuentes sobre nuestra solución de inversiones."
       icono={iconoPreguntasInversionistas}
       preguntas={faqInversiones}
      />

     </Box>
    </Box>
   </Container>
  </Box>
 );
}